import { apiSlice } from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBrokerListByTitleBranch: builder.mutation({
			query: (credentials) => ({
				url: `api/v1/admin/parent_child/broker_title_search?organization=${credentials?.orgName}&title=${credentials?.title}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetBrokerListByTitleBranchMutation } = applicationApiSlice;
