import React from "react";

// mui components
import { MenuItem, Select } from "@mui/material";

// custom components
import Heading5 from "../../typography/heading-05";

// mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// custom styles
import styles from "./index.module.scss";

// mui styles
import "./mui-styles.scss";

const SecondarySelect = ({
	id,
	label,
	displayEmpty,
	fullWidth,
	value,
	handleChange,
	disabled,
	error,
	options,
	extraClass,
	showOrgName,
}) => {
	const customClass = `${styles.secondarySelect} ${extraClass} ${
		fullWidth ? styles.fullWidth : ""
	} ${error ? styles.error : ""}`;

	return (
		<Select
			labelId={`simple-select-${id}-label`}
			id={`simple-select-${id}`}
			label={label}
			displayEmpty={displayEmpty}
			value={value}
			onChange={handleChange}
			className={customClass}
			IconComponent={ExpandMoreIcon}
			disabled={disabled}
			defaultValue=""
		>
			<MenuItem value="" disabled>
				<Heading5 text="Select" />
			</MenuItem>
			{options?.map((item, index) => (
				<MenuItem
					value={item.value}
					key={index}
					sx={{ justifyContent: "space-between", gap: 2 }}
				>
					<Heading5 text={item.label} />

					{showOrgName && (
						<Heading5
							extraClass={styles.orgName}
							text={item?.organization?.name}
						/>
					)}
				</MenuItem>
			))}
		</Select>
	);
};

export default SecondarySelect;
