import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// api slice
import { useGetSiblingOrganizationDetailsMutation } from "../../features/sibling-organization-detail/siblingOrganizationDetailApiSlice";

// redux slice
import {
	selectChildOrganizationName,
	selectUserDetails,
	setChildOrganizationName,
} from "../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../features/admin/impersonated-user/impersonatedUserSlice";

// mui components
import {
	Box,
	CssBaseline,
	AppBar,
	Stack,
	Toolbar,
	IconButton,
} from "@mui/material";

// mui icons
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowForwardIosOutlined } from "@mui/icons-material";

// custom components
import CustomDrawer from "../custom-drawer";
import SecondarySelect from "../custom-select/secondary-select";
import Heading3 from "../typography/heading-03";
import Heading4 from "../typography/heading-04";

// helper utils
import {
	selectedUserWithAccessLevel,
	selectedUserWithLoanChannel,
} from "../../utils/common-helpers";
import { TPO_SECONDARY } from "../../utils/common-constants";

// custom modal
import ConfirmationModal from "../modal/confirmation-modal";

// custom styles
import styles from "./index.module.scss";

const drawerWidth = 240;

const CustomLayout = ({ breadcrumb, children, disabledOverflow }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [mobileOpen, setMobileOpen] = useState(false);

	const [getSiblingOrganizationDetails] =
		useGetSiblingOrganizationDetailsMutation();

	const childOrganizationName = useSelector(selectChildOrganizationName);
	const [childOrganizationList, setChildOrganizationList] = useState([]);

	const [confirmModalText, setConfirmModalText] = useState("");
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const handleOpenConfirmModal = () => {
		setConfirmModalVisible(true);
	};
	const handleCloseConfirmModal = () => {
		setConfirmModalVisible(false);
	};

	const handleSubmitConfirmModal = () => {
		navigate("/admin");
	};

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	console.log("45 user details:", userDetails);
	console.log("45 impersonated user:", impersonatedUser);

	const isAdmin = userDetails?.user_roles?.includes("admin");
	const isImpersonatedUser =
		impersonatedUser?.email !== undefined &&
		impersonatedUser?.email !== null &&
		impersonatedUser?.email !== "";

	console.log("55 is admin:", isAdmin);
	console.log("55 is impersonated user:", isImpersonatedUser);

	const currentPath = location.pathname;
	const accessLevel = userDetails?.access_level;

	const allowedPagesForRestrictedUsers = [
		"/create-loan",
		"/new-loan",
		"/edit-loan",
	];

	const shouldRenderOrgSelector =
		accessLevel !== "" &&
		accessLevel !== "broker admin" &&
		accessLevel !== "secondary"
			? true
			: allowedPagesForRestrictedUsers.includes(currentPath);

	console.log("160 render:", shouldRenderOrgSelector);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleDrawerItemClick = (title, url) => {
		handleDrawerToggle();
		console.log("75 title:", title);
		console.log("75 url:", url);

		// TODO remove if condition when price my loan is not external link
		if (title === "Price my Loan") {
			window.open("https://www.lfclift.com/", "_blank");
		} else if (title === "Order Appraisal") {
			window.open("https://lender.reggora.com/auth/login", "_blank");
		} else if (title === "Schedule Training") {
			window.open(
				"https://appt.link/train-with-logan-finance/submission-portal-training",
				"_blank"
			);
		} else if (title === "Training Videos") {
			window.open("https://lfctpo.com/portal-training-videos", "_blank");
		} else {
			if (url === "/new-loan" && isAdmin) {
				if (isImpersonatedUser) {
					navigate(url);
				} else {
					setConfirmModalText("Sign in as a broker to gain access.");
					handleOpenConfirmModal();
				}
			} else {
				navigate(url);
			}
		}
	};

	useEffect(() => {
		const getSiblingOrganizationDetailsFunc = async () => {
			try {
				const siblingOrganizationList =
					await getSiblingOrganizationDetails().unwrap();
				console.log("120 sibling org list:", siblingOrganizationList?.data);

				let updatedArr = siblingOrganizationList?.data?.map((item) => ({
					...item,
					label: item?.name || "",
					value: item?.name || "",
				}));

				setChildOrganizationList(updatedArr);
			} catch (err) {
				console.log("260 error:", err);
			}
		};

		if (userDetails?.is_child_org || userDetails?.child_org_name !== "") {
			getSiblingOrganizationDetailsFunc();
		}
	}, [userDetails]);

	const handleChangeOrganization = (value) => {
		console.log("115 value:", value);
		navigate("/pipeline");
		dispatch(setChildOrganizationName(value));
	};

	return (
		<Box className={styles.rootContainer}>
			<CssBaseline />
			<CustomDrawer
				drawerWidth={drawerWidth}
				handleDrawerItemClick={handleDrawerItemClick}
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
			/>

			{/* content right */}
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 0,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					height: disabledOverflow && "100vh",
				}}
				className={styles.mainContainer}
			>
				<AppBar position="relative" className={styles.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: "none" } }}
							className={styles.menuIcon}
						>
							<MenuIcon />
						</IconButton>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							className={styles.toolbarStack}
						>
							{/* breadcrumbs */}
							{breadcrumb?.length > 0 && (
								<Stack direction="row" className={styles.breadcrumbContainer}>
									{breadcrumb?.map((item, index) => (
										<Stack direction="row" alignItems="center" key={index}>
											<Heading3
												key={index}
												text={item.label}
												fontType="semibold"
												// do not click last item
												handleClick={() =>
													breadcrumb?.length !== index + 1 &&
													navigate(item?.path)
												}
												extraClass={
													breadcrumb?.length !== index + 1
														? styles.breadcrumb
														: ""
												}
											/>

											{breadcrumb?.length !== index + 1 && (
												<Box className={styles.breadcrumbIconContainer}>
													<ArrowForwardIosOutlined
														className={styles.breadcrumbIcon}
													/>
												</Box>
											)}
										</Stack>
									))}
								</Stack>
							)}

							<Stack flexDirection="row" alignItems="center" gap={2}>
								{/* user role */}
								<Heading4
									text={
										(userDetails?.user_roles?.includes("manager") ||
										userDetails?.user_roles?.includes("AM")
											? "Account Manager"
											: userDetails?.user_roles?.includes("executive") ||
											  userDetails?.user_roles?.includes("AE")
											? "Account Executive"
											: userDetails?.user_roles?.includes("admin")
											? impersonatedUser?.email !== null &&
											  impersonatedUser?.email !== undefined &&
											  impersonatedUser?.email !== ""
												? `Admin Logged in as ${(impersonatedUser?.email).toLowerCase()}`
												: "Admin"
											: userDetails?.access_level === TPO_SECONDARY
											? TPO_SECONDARY
											: userDetails?.access_level?.includes("admin")
											? "Broker Admin"
											: "Broker") +
										` ${
											selectedUserWithLoanChannel(userDetails, impersonatedUser)
												?.loan_channel
												? `(${
														selectedUserWithLoanChannel(
															userDetails,
															impersonatedUser
														)?.loan_channel
												  })`
												: ""
										}
									 ${
											userDetails?.user_roles?.includes("admin") &&
											impersonatedUser?.email !== null &&
											impersonatedUser?.email !== undefined &&
											impersonatedUser?.email !== "" &&
											selectedUserWithAccessLevel(userDetails, impersonatedUser)
												?.access_level === TPO_SECONDARY
												? TPO_SECONDARY
												: ""
										}`
									}
									extraClass={styles.userRoleText}
								/>

								{userDetails?.is_child_org && shouldRenderOrgSelector && (
									<SecondarySelect
										value={childOrganizationName || ""}
										options={childOrganizationList}
										handleChange={(event) =>
											handleChangeOrganization(event.target.value)
										}
										displayEmpty={true}
										fullWidth={true}
										extraClass={styles.organizationDropdown}
									/>
								)}
							</Stack>
						</Stack>
					</Toolbar>
				</AppBar>

				{children}
			</Box>
			{/* modal */}
			<ConfirmationModal
				open={confirmModalVisible}
				text={confirmModalText}
				confirmText="Sign In"
				handleConfirm={handleSubmitConfirmModal}
				closeText="Close"
				handleClose={handleCloseConfirmModal}
			/>
		</Box>
	);
};

export default CustomLayout;
