import { apiSlice } from "../../app/api/apiSlice";

export const applicationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSiblingOrganizationDetails: builder.mutation({
			query: () => ({
				url: `/api/v1/organization/get_fellow_child_orgs/`,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetSiblingOrganizationDetailsMutation } = applicationApiSlice;
