import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// mui components
import { Box, Stack, Tab, Tabs } from "@mui/material";

// api slices
import { useGetBorrowerPairMutation } from "../../../../features/get-borrower-pair/getBorrowerPairApiSlice";
import { useFieldMutation } from "../../../../features/field-values/fieldValueApiSlice";
import { useGetLoanSummaryQuery } from "../../../../features/loanSummary/loanSummaryApiSlice";
import { useGetOrganizationDetailsMutation } from "../../../../features/organization-detail/organizationDetailApiSlice";

// mui icons
import { ArrowForwardOutlined } from "@mui/icons-material";

// custom components
import { TabPanel } from "../../../../components/tab-panel";
import ViewLoanInfoCard from "../info-card";
import BorrowerInformation from "../../../../components/loan-form-card/borrower-information";
import PropertyDetails from "../../../../components/loan-form-card/property-details";
import LoanOriginator from "../../../../components/loan-form-card/loan-originator";
import LoanProcessor from "../../../../components/loan-form-card/loan-processor";
import PrimaryButton from "../../../../components/buttons/primary-button";
import Heading6 from "../../../../components/typography/heading-06";

// custom modal

// helper utils
import { handleSetPropertyDetails } from "../../../../utils/loan-submission/set-property-details";

// custom styles
import styles from "./index.module.scss";
import HelpTextModal from "../../../../components/modal/help-text-modal";
import AdditionalLoanContact from "../../../../components/loan-form-card/additional-loan-contact";
import { selectedUserWithLoanChannel } from "../../../../utils/common-helpers";
import { LOAN_CHANNEL_CONSTANTS } from "../../../../utils/common-constants";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../../features/admin/impersonated-user/impersonatedUserSlice";

const ViewLoanApplication = ({
	setLoanApplicationStage,
	loanGuid,
	handleOpenLoaderModal,
	handleCloseLoaderModal,
	currentStage,
}) => {
	console.log("27 loan id:", loanGuid);
	const params = useParams();
	const loanNumber = params?.loanId;

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const [field, { isLoading: loadingValues }] = useFieldMutation();
	const { data: loanData } = useGetLoanSummaryQuery(loanGuid);

	const [getBorrowerPair] = useGetBorrowerPairMutation();

	const [data, setData] = useState({ applications: [] });
	const [fieldValues, setFieldValues] = useState([]);

	const [loanApplicationData, setLoanApplicationData] = useState([{}]);

	const [foreignNationalIndex, setForeignNationalIndex] = useState([]);

	const [tabValue, setTabValue] = useState(0);

	const [error, setError] = useState("");

	const [propertyInfo, setPropertyInfo] = useState({});
	const [grossRent, setGrossRent] = useState("");

	const [homeownersInsurance, setHomeownersInsurance] = useState("");
	const [floodInsurance, setFloodInsurance] = useState("");
	const [propertyTaxes, setPropertyTaxes] = useState("");
	const [realEstateTaxes, setRealEstateTaxes] = useState("");
	const [hoaDues, setHoaDues] = useState("");

	useEffect(() => {
		setPropertyInfo(data?.property);
	}, [data]);

	const [organizationName, setOrganizationName] = useState("");
	const [organizationDetails, setOrganizationDetails] = useState({});

	const [childOrganizationName, setChildOrganizationName] = useState("");
	const [childOrganizationDetails, setChildOrganizationDetails] = useState({});

	const [currentOrgName, setCurrentOrgName] = useState({});
	const [currentOrgDetails, setCurrentOrgDetails] = useState({});

	const [organizationLicense, setOrganizationLicense] = useState("");

	const [loanOriginatorEmail, setLoanOriginatorEmail] = useState("");
	const [organizationUsers, setOrganizationUsers] = useState([]);
	const [loanOriginator, setLoanOriginator] = useState({});

	const [loanProcessorEmail, setLoanProcessorEmail] = useState("");
	const [organizationProcessor, setOrganizationProcessor] = useState([]);
	const [loanProcessor, setLoanProcessor] = useState({});

	const [additionalContact, setAdditionalContact] = useState({
		name: "",
		phone: "",
		email: "",
	});

	const [manualOriginator, setManualOriginator] = useState(false);
	const [originatorData, setOriginatorData] = useState({
		orgName: "",
		address: "",
		unitNumber: "",
		city: "",
		state: "",
		zipcode: "",
		orgNmls: "",
		orgStateLicense: "",
		originatorEmail: "",
		originatorFirstName: "",
		originatorLastName: "",
		originatorNmls: "",
		originatorStateLicense: "",
		originatorPhone: "",
	});

	const [helpText, setHelpText] = useState("");
	const [extraHelpText, setExtraHelpText] = useState("");
	const [helpTextOpen, setHelpTextOpen] = useState(false);

	const handleOpenHelpText = () => {
		setHelpTextOpen(true);
	};

	const handleCloseHelpText = () => {
		setHelpTextOpen(false);
	};

	const [getOrganizationDetails, { isLoading: organizationDetailsLoading }] =
		useGetOrganizationDetailsMutation();

	const isExecutiveOrManager =
		userDetails?.user_roles?.includes("executive") ||
		userDetails?.user_roles?.includes("manager") ||
		userDetails?.user_roles?.includes("AE") ||
		userDetails?.user_roles?.includes("AM");

	console.log("270 is executive or manager:", isExecutiveOrManager);

	useEffect(() => {
		console.log("50 loan data:", loanData);

		if (loanData?.data !== undefined) {
			setGrossRent(loanData?.data?.subjectPropertyGrossRentalIncomeAmount);

			handleSetPropertyDetails(
				loanData?.data,
				setHomeownersInsurance,
				setFloodInsurance,
				setPropertyTaxes,
				setRealEstateTaxes,
				setHoaDues
			);
		}

		setData(loanData?.data);
	}, [loanData]);

	const getFieldValues = async (loanId) => {
		console.log("175 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields: "2306,TPO.X67,TPO.X80",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			setFieldValues(getValues?.response);
		} catch (err) {}
	};

	useEffect(() => {
		console.log("160 loan guid:", loanGuid);
		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	useEffect(() => {
		console.log("111 data:", data);
		console.log("111 data tpo:", data?.tPO);
		if (data?.tPO !== undefined && fieldValues?.length > 0) {
			setOrganizationName(data?.tPO?.companyName);
			setChildOrganizationName(data?.tPO?.branchName || "");

			console.log("110 field values:", fieldValues);

			let originatorEmail;
			let originatorName;
			let originatorPhone;

			if (isExecutiveOrManager) {
				originatorEmail = data?.interviewerEmail;
				originatorName = data?.inverviewerName;
				originatorPhone = data?.interviewerPhoneNumber;
			} else {
				originatorEmail =
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
						? data?.interviewerEmail
						: data?.tPO?.lOEmail || "";

				originatorName =
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
						? data?.inverviewerName
						: data?.tPO?.lOName || "";

				originatorPhone =
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
						? data?.interviewerPhoneNumber
						: data?.tPO?.lOBusinessPhone || "";
			}

			console.log("220 new lo email:", originatorEmail);
			setLoanOriginatorEmail(originatorEmail);

			let user = {};
			user.email = originatorEmail;
			user.phone = originatorPhone;
			let organizationUserInfo = { user };

			console.log("120 organizationUserInfo:", [organizationUserInfo]);
			setOrganizationUsers([{ label: user.email, value: user.email }]);

			let userInfo = {};
			let userChild = {};
			userChild.email = originatorEmail;
			userChild.name = originatorName;
			userInfo.phone = originatorPhone;

			userInfo.stateLicense = fieldValues?.find(
				(o) => o.fieldId === "2306"
			)?.value;
			userInfo.cell_phone = fieldValues?.find(
				(o) => o.fieldId === "TPO.X67"
			)?.value;

			userInfo["nmls_id"] = data?.nmlsLoanOriginatorId;
			userInfo = { ...userInfo, user: userChild };
			console.log("95 user info:", userInfo);
			setLoanOriginator(userInfo);

			setLoanProcessorEmail(data?.tPO?.lPEmail);
			let processorUser = {};
			processorUser.email = data?.tPO?.lPEmail;
			processorUser.phone = data?.tPO?.lPBusinessPhone;
			let organizationProcessorUserInfo = { user: processorUser };

			console.log("120 processorUser:", [organizationProcessorUserInfo]);
			setOrganizationProcessor([
				{ label: processorUser.email, value: processorUser.email },
			]);

			let processorUserInfo = {};
			let processorUserChild = {};
			processorUserChild.email = data?.tPO?.lPEmail;
			processorUserChild.name = data?.tPO.lPName;
			processorUserInfo.phone = data?.tPO?.lPBusinessPhone;
			processorUserInfo["nmls_id"] = data?.nmlsLoanOriginatorId;
			processorUserInfo.cell_phone = fieldValues?.find(
				(o) => o.fieldId === "TPO.X80"
			)?.value;
			processorUserInfo = { ...processorUserInfo, user: processorUserChild };
			setLoanProcessor(processorUserInfo);
		}
	}, [data, fieldValues]);

	useEffect(() => {
		const getOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				organizationName,
			}).unwrap();
			setOrganizationDetails(searchResults);
		};

		if (organizationName !== undefined && organizationName !== "") {
			getOrganizationDetailsFunc();
		}
	}, [organizationName]);

	useEffect(() => {
		const getChildOrganizationDetailsFunc = async () => {
			const searchResults = await getOrganizationDetails({
				childOrganizationName,
			}).unwrap();
			setChildOrganizationDetails(searchResults);
		};

		if (childOrganizationName !== undefined && childOrganizationName !== "") {
			getChildOrganizationDetailsFunc();
		}
	}, [childOrganizationName]);

	useEffect(() => {
		if (userDetails?.is_child_org || userDetails?.child_org_name !== "") {
			setCurrentOrgName(childOrganizationName);
			setCurrentOrgDetails(childOrganizationDetails);
		} else {
			setCurrentOrgName(organizationName);
			setCurrentOrgDetails(organizationDetails);
		}
	}, [
		organizationName,
		organizationDetails,
		childOrganizationName,
		childOrganizationDetails,
	]);

	useEffect(() => {
		const getAllBorrowerPair = async () => {
			const borrowerPairData = await getBorrowerPair({
				loanGuid,
			}).unwrap();

			let pairArr = [];

			console.log("200 borrowerPairData:", borrowerPairData);

			borrowerPairData?.response?.map((item) =>
				pairArr.push(item.borrower, item.coborrower)
			);

			console.log("200 pairArr:", pairArr);

			try {
				const getValues = await field({
					loan_guid: loanGuid,
					fields:
						"CX.FOREIGNNATLBORR1,CX.FOREIGNNATLBORR2,CX.FOREIGNNATLBORR3,CX.FOREIGNNATLBORR4,CX.FOREIGNNATLBORR5,CX.FOREIGNNATLBORR6,CX.LOLP.SELECT.MANUAL.METHOD,TPO.X61,TPO.X65,TPO.X63,315,URLA.X188,URLA.X190,313,321,323,3237,3629,3968,URLA.X170,URLA.X172,3238,2306,1823",
				}).unwrap();

				console.log("175 all fields:", getValues?.response);
				console.log("240 pair arr:", pairArr);

				let updatedBorrowerPairs = [];
				let foreignIndex = [];

				if (getValues?.response?.length > 0) {
					let manualOriginatorValue =
						getValues?.response?.find(
							(item) => item.fieldId === "CX.LOLP.SELECT.MANUAL.METHOD"
						).value === "Y";

					console.log("320 manual originator Value:", manualOriginatorValue);
					setManualOriginator(manualOriginatorValue);

					if (manualOriginatorValue) {
						let orgName = getValues?.response?.find(
							(item) => item?.fieldId === "315"
						)?.value;
						let address = getValues?.response?.find(
							(item) => item?.fieldId === "URLA.X188"
						)?.value;
						let unitNumber = getValues?.response?.find(
							(item) => item?.fieldId === "URLA.X190"
						)?.value;
						let city = getValues?.response?.find(
							(item) => item?.fieldId === "313"
						)?.value;
						let state = getValues?.response?.find(
							(item) => item?.fieldId === "321"
						)?.value;
						let zipcode = getValues?.response?.find(
							(item) => item?.fieldId === "323"
						)?.value;
						let orgNmls = getValues?.response?.find(
							(item) => item?.fieldId === "3237"
						)?.value;
						let orgStateLicense = getValues?.response?.find(
							(item) => item?.fieldId === "3629"
						)?.value;
						let originatorEmail = getValues?.response?.find(
							(item) => item?.fieldId === "3968"
						)?.value;
						let originatorFirstName = getValues?.response?.find(
							(item) => item?.fieldId === "URLA.X170"
						)?.value;
						let originatorLastName = getValues?.response?.find(
							(item) => item?.fieldId === "URLA.X172"
						)?.value;
						let originatorNmls = getValues?.response?.find(
							(item) => item?.fieldId === "3238"
						)?.value;
						let originatorStateLicense = getValues?.response?.find(
							(item) => item?.fieldId === "2306"
						)?.value;
						let originatorPhone = getValues?.response?.find(
							(item) => item?.fieldId === "1823"
						)?.value;

						console.log(
							"380 manual originator data:",
							orgName,
							address,
							unitNumber,
							city,
							state,
							zipcode,
							orgNmls,
							orgStateLicense,
							originatorEmail,
							originatorFirstName,
							originatorLastName,
							originatorNmls,
							originatorStateLicense,
							originatorPhone
						);

						setOriginatorData({
							orgName: orgName,
							address: address,
							unitNumber: unitNumber,
							city: city,
							state: state,
							zipcode: zipcode,
							orgNmls: orgNmls,
							orgStateLicense: orgStateLicense,
							originatorEmail: originatorEmail,
							originatorFirstName: originatorFirstName,
							originatorLastName: originatorLastName,
							originatorNmls: originatorNmls,
							originatorStateLicense: originatorStateLicense,
							originatorPhone: originatorPhone,
						});
					}

					let name = getValues?.response?.find(
						(item) => item?.fieldId === "TPO.X61"
					)?.value;
					let phone = getValues?.response?.find(
						(item) => item?.fieldId === "TPO.X65"
					)?.value;
					let email = getValues?.response?.find(
						(item) => item?.fieldId === "TPO.X63"
					)?.value;

					console.log("330 additional values:", name, phone, email);
					setAdditionalContact({ name: name, phone: phone, email: email });

					for (let index = 0; index < pairArr.length; index++) {
						const item = pairArr[index];
						const updatedItem = { ...item };

						if (getValues?.response[index]?.value === "Yes") {
							updatedItem.urla2020CitizenshipResidencyType = "Foreign National";
							foreignIndex.push(index);
						}

						updatedBorrowerPairs.push(updatedItem);
					}

					console.log("250 updated foreign index:", foreignIndex);
					console.log("250 updated borrower pair:", updatedBorrowerPairs);
					setForeignNationalIndex(foreignIndex);
					setLoanApplicationData(updatedBorrowerPairs);
					handleCloseLoaderModal();
				}
			} catch (err) {
				console.log("270 error:", err);
			}
		};

		console.log("300 manual field:", manualOriginator);

		if (loanGuid !== null && loanGuid !== undefined && loanGuid !== "") {
			handleOpenLoaderModal();
			getAllBorrowerPair();
		}
	}, [loanGuid]);

	const handleNext = async () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{/* tabs section */}
				{loanApplicationData?.length > 2 && (
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="borrower tabs"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 1"
										fontType="semibold"
										extraClass={
											tabValue === 0 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(0)}
						/>
						<Tab
							className={styles.tabBtnContainer}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text="Borrower Pair 2"
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(1)}
						/>
						{loanApplicationData?.length > 4 && (
							<Tab
								className={styles.tabBtnContainer}
								label={
									<Stack direction="row" className={styles.tabLabelContainer}>
										<Heading6
											text="Borrower Pair 3"
											fontType="semibold"
											extraClass={
												tabValue === 2 ? styles.activeTabText : styles.tabText
											}
										/>
									</Stack>
								}
								{...a11yProps(2)}
							/>
						)}
					</Tabs>
				)}

				{/* borrower pair 1 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						index={0}
						value={tabValue}
						className={tabValue === 0 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[0]}
							coBorrower={loanApplicationData[1]}
							propertyInfo={propertyInfo}
						/>
						{loanApplicationData?.slice(0, 2)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							key={Math.floor(Math.random() * 100)}
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={data}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								disabled={true}
							/>
						)}
					</TabPanel>
				)}

				{/* borrower pair 2 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						index={1}
						value={tabValue}
						className={tabValue === 1 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[2]}
							coBorrower={loanApplicationData[3]}
							propertyInfo={propertyInfo}
						/>
						{loanApplicationData?.slice(2, 4)?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 2}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							key={Math.floor(Math.random() * 100)}
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={data}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								disabled={true}
							/>
						)}
					</TabPanel>
				)}

				{/* borrower pair 3 */}
				{loanApplicationData?.length > 2 && (
					<TabPanel
						index={2}
						value={tabValue}
						className={tabValue === 2 ? styles.tabPanelContainer : ""}
					>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[4]}
							coBorrower={loanApplicationData[5]}
							propertyInfo={propertyInfo}
						/>
						{loanApplicationData?.slice(4, 6)?.map((borrower, index = 4) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index + 4}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							key={Math.floor(Math.random() * 100)}
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={data}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								disabled={true}
							/>
						)}
					</TabPanel>
				)}

				{/* default view no tabs */}
				{loanApplicationData?.length <= 2 && (
					<Box className={styles.tabPanelContainer}>
						<ViewLoanInfoCard
							loanNumber={loanNumber}
							borrower={loanApplicationData[0]}
							coBorrower={loanApplicationData[1]}
							propertyInfo={propertyInfo}
						/>

						{loanApplicationData?.map((borrower, index) => (
							<BorrowerInformation
								key={index}
								borrowerIndex={index}
								coBorrower={index % 2 !== 0}
								borrowerData={borrower}
								foreignNationalIndex={foreignNationalIndex}
								setForeignNationalIndex={setForeignNationalIndex}
								data={loanApplicationData}
								setData={setLoanApplicationData}
								error={error.includes("provide a value")}
								disabled={true}
								viewLoan={true}
							/>
						))}

						<PropertyDetails
							key={Math.floor(Math.random() * 100)}
							data={propertyInfo}
							setData={setPropertyInfo}
							loanData={data}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
							grossRent={grossRent}
							setGrossRent={setGrossRent}
							homeownersInsurance={homeownersInsurance}
							setHomeownersInsurance={setHomeownersInsurance}
							floodInsurance={floodInsurance}
							setFloodInsurance={setFloodInsurance}
							propertyTaxes={propertyTaxes}
							setPropertyTaxes={setPropertyTaxes}
							realEstateTaxes={realEstateTaxes}
							setRealEstateTaxes={setRealEstateTaxes}
							hoaDues={hoaDues}
							setHoaDues={setHoaDues}
							setHelpText={setHelpText}
							handleOpenHelpText={handleOpenHelpText}
						/>

						<LoanOriginator
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanOriginatorEmail={loanOriginatorEmail}
							setLoanOriginatorEmail={setLoanOriginatorEmail}
							organizationUsers={organizationUsers}
							loanOriginator={loanOriginator}
							setOrganizationLicense={setOrganizationLicense}
							propertyState={propertyInfo?.state}
							manualOriginator={manualOriginator}
							setManualOriginator={setManualOriginator}
							originatorData={originatorData}
							setOriginatorData={setOriginatorData}
							setHelpText={setHelpText}
							setExtraHelpText={setExtraHelpText}
							handleOpenHelpText={handleOpenHelpText}
							disabled={true}
							viewLoan={true}
						/>

						<LoanProcessor
							organizationName={currentOrgName}
							organizationDetails={currentOrgDetails}
							loanProcessorEmail={loanProcessorEmail}
							setLoanProcessorEmail={setLoanProcessorEmail}
							organizationProcessor={organizationProcessor}
							loanProcessor={loanProcessor}
							propertyState={propertyInfo?.state}
							error={error.includes("provide a value")}
							disabled={true}
							viewLoan={true}
						/>

						{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
							selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR) && (
							<AdditionalLoanContact
								additionalContact={additionalContact}
								setAdditionalContact={setAdditionalContact}
								disabled={true}
							/>
						)}
					</Box>
				)}
			</Stack>

			<Stack direction="row" className={styles.footerContainer}>
				<PrimaryButton
					text="Continue"
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					onClick={handleNext}
				/>
			</Stack>

			{/* modals */}
			<HelpTextModal
				open={helpTextOpen}
				modalText={helpText}
				extraHelpText={extraHelpText}
				handleClose={handleCloseHelpText}
			/>
		</Stack>
	);
};

export default ViewLoanApplication;
