import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui components
import { Box, Stack } from "@mui/material";

// mui icons
import CloseIcon from "@mui/icons-material/Close";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// custom components
import Body1 from "../../typography/body-01";
import Heading3 from "../../typography/heading-03";
import PrimaryButton from "../../buttons/primary-button";
import TertiaryButton from "../../buttons/tertiary-button";

// helper functions
import { filterValue } from "../../../utils/filter-value";
import {
	LOAN_CHANNEL_CONSTANTS,
	TPO_SECONDARY,
} from "../../../utils/common-constants";
import {
	selectedUserWithAccessLevel,
	selectedUserWithLoanChannel,
} from "../../../utils/common-helpers";

// api services
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";

// reducer slices
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// custom styles
import styles from "./index.module.scss";

const LockInfoCard = ({ loanData, handleTabChange }) => {
	const [field] = useFieldMutation();
	const [fieldValues, setFieldValues] = useState([]);

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const getFieldValues = async () => {
		console.log("75 loan id:", loanData?.encompassId);
		console.log("75 loan number:", loanData?.loanNumber);

		try {
			const getValues = await field({
				loan_guid: loanData?.encompassId,
				fields:
					"2,URLA.X73,URLA.X75,12,14,4000,4001,4002,4004,4005,4006,3,2400,VASUMM.X23,Log.MS.CurrentMilestone,1401,LCP.X1,CX.LOCKREQUESTTIMESTAMP,2149,2151,3420,2202,2203,2160",
			}).unwrap();

			console.log("75 all fields:", getValues?.response);

			if (getValues?.response?.length > 0) {
				setFieldValues(getValues?.response);
			}
		} catch (err) {
			console.log("270 error:", err);
		}
	};

	useEffect(() => {
		getFieldValues();
	}, []);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				className={styles.topContainer}
			>
				<Heading3
					text="Lock Information and Activities"
					extraClass={styles.tabTitle}
					fontType="semibold"
				/>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					className={styles.topBtnContainer}
				>
					<TertiaryButton
						text="Close this view"
						startIcon={<CloseIcon />}
						onClick={(e) => handleTabChange(e, false)}
					/>
					<PrimaryButton
						text="Check Pricing & Lock"
						startIcon={<LockOutlinedIcon className={styles.primaryBtnIcon} />}
						onClick={(e) => handleTabChange(e, 9)}
						disabled={false}
					/>
				</Stack>
			</Stack>

			<Stack direction="row" className={styles.bottomContainer}>
				<Stack direction="column" className={styles.infoContainer}>
					{!(
						selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
						selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
					) && (
						<Stack
							direction="row"
							alignItems="center"
							className={styles.infoDetailContainer}
						>
							<Box className={styles.infoDetailTitle}>
								<Body1 text="Comp Plan" extraClass={styles.labelText} />
							</Box>
							<Box className={styles.infoDetailValue}>
								<Body1
									text={filterValue(fieldValues, "LCP.X1") || "NA"}
									extraClass={styles.valueText}
								/>
							</Box>
						</Stack>
					)}

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Lock Requested Date" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={
									filterValue(fieldValues, "CX.LOCKREQUESTTIMESTAMP") || "NA"
								}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Lock Date" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldValues, "2149") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1
								text="Lock Expiration Date"
								extraClass={styles.labelText}
							/>
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldValues, "2151") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						className={styles.infoDetailContainer}
					>
						<Box className={styles.infoDetailTitle}>
							<Body1 text="Loan Program" extraClass={styles.labelText} />
						</Box>
						<Box className={styles.infoDetailValue}>
							<Body1
								text={filterValue(fieldValues, "1401") || "NA"}
								extraClass={styles.valueText}
							/>
						</Box>
					</Stack>

					{!(
						selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
						selectedUserWithLoanChannel(userDetails, impersonatedUser)
							?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
					) ||
					selectedUserWithAccessLevel(userDetails, impersonatedUser)
						?.access_level === TPO_SECONDARY ? (
						<>
							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1 text="Base Buy Price" extraClass={styles.labelText} />
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={Number(filterValue(fieldValues, "3420")).toFixed(3)}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1
										text="Total Price Adjustments"
										extraClass={styles.labelText}
									/>
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={Number(filterValue(fieldValues, "2202")).toFixed(3)}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1 text="Net Buy Price" extraClass={styles.labelText} />
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={Number(filterValue(fieldValues, "2203")).toFixed(3)}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>

							<Stack
								direction="row"
								alignItems="center"
								className={styles.infoDetailContainer}
							>
								<Box className={styles.infoDetailTitle}>
									<Body1 text="Net Buy Rate" extraClass={styles.labelText} />
								</Box>
								<Box className={styles.infoDetailValue}>
									<Body1
										text={Number(filterValue(fieldValues, "2160")).toFixed(3)}
										extraClass={styles.valueText}
									/>
								</Box>
							</Stack>
						</>
					) : null}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default LockInfoCard;
