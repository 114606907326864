import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui components
import { Stack, Tabs, Tab, InputLabel } from "@mui/material";

// custom components
import ViewLoanInfoCard from "../../../pages/view-loan/components/info-card";
import EmptyTextContainer from "../../empty-text-container";
import { TabPanel } from "../../tab-panel";
import RequiredError from "../../required-error";
import PrimaryButton from "../../buttons/primary-button";
import SecondaryButton from "../../buttons/secondary-button";
import Heading6 from "../../typography/heading-06";
import EligibleFilter from "../../loan-form-card/eligible-filter";
import IneligibleFilter from "../../loan-form-card/ineligible-filter";
import TemporaryDrawer from "../../temporary-drawer";
import Heading5 from "../../typography/heading-05";
import Body3 from "../../typography/body-03";
import SecondarySelect from "../../custom-select/secondary-select";

// mui icons
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

// api slices
import { useGetLoanMutation } from "../../../features/get-loan-data/getLoanApiSlice";
import { useGetEligibleProductsMutation } from "../../../features/get-eligible-products/getEligibleProductsApiSlice";
import { useGetEligibleProductDetailsMutation } from "../../../features/get-eligible-product-details/getEligibleProductDetailsApiSlice";
import { useGetIneligibleProductsMutation } from "../../../features/get-ineligible-products/getIneligibleProductsApiSlice";
import { useUpdateLoanWithProductMutation } from "../../../features/update-loan-with-product/updateLoanWithProductApiSlice";
import { usePushDataToEncompassMutation } from "../../../features/push-data-to-encompass/pushDataToEncompassApiSlice";
import { useFieldMutation } from "../../../features/field-values/fieldValueApiSlice";
import { useCheckQueueStatusMutation } from "../../../features/check-queue-status/checkQueueStatusApiSlice";
import { useUpdateFieldsMutation } from "../../../features/update-fields/updateFieldsApiSlice";

// reducer slices
import { selectUserDetails } from "../../../features/auth/authSlice";
import { selectImpersonatedUser } from "../../../features/admin/impersonated-user/impersonatedUserSlice";

// helper utils
import {
	BROKER_ADMIN,
	LOAN_CHANNEL_CONSTANTS,
	TPO_SECONDARY,
} from "../../../utils/common-constants";
import {
	selectedUserWithAccessLevel,
	selectedUserWithLoanChannel,
} from "../../../utils/common-helpers";
import { compensationPlanOptions } from "../../../utils/select-options";

// custom modal
import LoaderTextModal from "../../modal/loader-text-modal";
import SuccessModal from "../../modal/success-modal";
import ErrorModal from "../../modal/error-modal";
import PricingModal from "../../modal/pricing-modal";
import ProcessingModal from "../../modal/processing-modal";
import LoaderModal from "../../modal/loader-modal";

// custom styles
import styles from "./index.module.scss";
import Body2 from "../../typography/body-02";

const LoanPricingApplication = ({
	setLoanApplicationStage,
	loanGuid,
	currentStage,
	viewLoan,
}) => {
	const params = useParams();
	const loanNumber = params?.loanId;

	console.log("50 loan id:", loanGuid);

	const [tabValue, setTabValue] = useState(0);

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const [getLoan, { isLoading: isGettingLoan }] = useGetLoanMutation();
	const [field] = useFieldMutation();
	const [updateFields, { isLoading: isUpdatingFields }] =
		useUpdateFieldsMutation();
	const [getEligibleProducts, { isLoading: isGettingEligibleProducts }] =
		useGetEligibleProductsMutation();
	const [
		getEligibleProductDetails,
		{ isLoading: isGettingEligibleProductDetails },
	] = useGetEligibleProductDetailsMutation();
	const [getIneligibleProducts, { isLoading: isGettingIneligibleProducts }] =
		useGetIneligibleProductsMutation();

	const [updateLoanWithProduct, { isLoading: isUpdatingLoanWithProduct }] =
		useUpdateLoanWithProductMutation();

	const [pushDataToEncompass, { isLoading: isPushingDataToEncompass }] =
		usePushDataToEncompassMutation();

	const [checkOrderStatus] = useCheckQueueStatusMutation();

	console.log("23 params:", params);
	console.log("23 loan id:", loanGuid);

	const [loanData, setLoanData] = useState({});
	const [eligibleProducts, setEligibleProducts] = useState([]);
	const [expandedProduct, setExpandedProduct] = useState("");
	const [eligibleProductDetails, setEligibleProductDetails] = useState({});
	const [selectedRow, setSelectedRow] = useState({});
	const [selectedProduct, setSelectedProduct] = useState({});
	console.log("40 selected product:", selectedProduct);
	console.log("40 selected eligible sub product:", selectedRow);

	const [ineligibleProducts, setIneligibleProducts] = useState([]);

	const [borrowerFirstName, setBorrowerFirstName] = useState("");
	const [borrowerMiddleName, setBorrowerMiddleName] = useState("");
	const [borrowerLastName, setBorrowerLastName] = useState("");

	const [coBorrowerFirstName, setCoBorrowerFirstName] = useState("");
	const [coBorrowerMiddleName, setCoBorrowerMiddleName] = useState("");
	const [coBorrowerLastName, setCoBorrowerLastName] = useState("");

	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [postalCode, setPostalCode] = useState("");

	const [loanEmail, setLoanEmail] = useState("");
	const [orgLoanChannel, setOrgLoanChannel] = useState("");

	const [compensationPlan, setCompensationPlan] = useState("");

	const [drawerData, setDrawerData] = useState({});
	const [drawerType, setDrawerType] = useState("");
	const [drawerOpen, setDrawerOpen] = useState({});

	const [isCheckingCreditReissueStatus, setIsCheckingCreditReissueStatus] =
		useState(false);
	let checkQueueStatusInterval = null;

	const userDetails = useSelector(selectUserDetails);
	const impersonatedUser = useSelector(selectImpersonatedUser);

	const handleOpenDrawer = () => {
		setDrawerOpen({
			right: true,
		});
	};

	const handleCloseDrawer = () => {
		setDrawerOpen({
			right: false,
		});
	};

	console.log("100 all products:", eligibleProducts);
	console.log("100 expanded id:", expandedProduct);
	console.log("100 product details:", eligibleProductDetails);

	const toggleDrawer = (event, drawerType, rowData) => {
		if (drawerType !== undefined && drawerType !== "") {
			console.log("eligible products:", eligibleProducts);
			console.log("expanded product:", expandedProduct);

			const parentProductDetails = eligibleProducts?.data?.products?.filter(
				(product) => product?.productId === expandedProduct
			);

			console.log("parent product details:", parentProductDetails);

			setDrawerData({
				loanData: loanData,
				parentProductDetails: parentProductDetails[0],
				productDetails: eligibleProductDetails,
				rowData: rowData,
			});
			setDrawerType(drawerType);
			handleOpenDrawer();
		} else {
			setDrawerType("");
			handleCloseDrawer();
		}
	};

	const [processingText, setProcessingText] = useState("");
	const [processingModalVisible, setProcessingModalVisible] = useState(false);

	const handleOpenProcessingModal = () => {
		setProcessingModalVisible(true);
	};

	const handleCloseProcessingModal = () => {
		setProcessingModalVisible(false);
	};

	const [successText, setSuccessText] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);

	const handleOpenSuccessModal = () => {
		setSuccessModalVisible(true);
	};

	const handleCloseSuccessModal = () => {
		setSuccessModalVisible(false);

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	const [errorText, setErrorText] = useState("");
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleOpenErrorModal = () => {
		setErrorModalVisible(true);
	};

	const handleCloseErrorModal = () => {
		setErrorModalVisible(false);
	};

	const [pricingModal, setPricingModal] = useState(false);

	const handleOpenPricingModal = () => {
		setPricingModal(true);
	};

	const handleClosePricingModal = () => {
		setPricingModal(false);
	};

	const checkQueueStatus = async (queueId) => {
		setIsCheckingCreditReissueStatus(true);

		var formData = new FormData();
		formData.append("ob_loan_id", eligibleProducts?.data?.ob_loan_id);
		formData.append(
			"ob_business_channel_id",
			eligibleProducts?.data?.ob_business_channel_id
		);
		formData.append(
			"ob_originator_id",
			eligibleProducts?.data?.ob_originator_id
		);
		formData.append("ob_queue_id", queueId);

		try {
			const response = await checkOrderStatus({
				queueId,
				formData,
			}).unwrap();
			console.log("265 queue status response:", response);

			clearTimeout(checkQueueStatusInterval);
			checkQueueStatusInterval = null;

			console.log("285 queue id:", queueId?.toString());

			let queueData = response?.data?.filter(
				(item) => item?.pushId === queueId?.toString()
			);

			console.log("290 data:", queueData);
			console.log("290 status:", queueData[0]?.pushRequestStatus);

			if (queueData[0]?.pushRequestStatus?.toLowerCase()?.includes("success")) {
				// success scenario
				console.log("300 queue successful");
				setIsCheckingCreditReissueStatus(false);
				handleClosePricingModal();
				handleOpenSuccessModal();
				setSuccessText(`Registered loan with Logan Pricing Desk.`);
			} else if (
				queueData[0]?.pushRequestStatus?.toLowerCase()?.includes("progress")
			) {
				// In-progress scenario
				console.log("300 queue in-progress");
				checkQueueStatusInterval = setTimeout(
					() => checkQueueStatus(queueId),
					3000
				);
			} else {
				// error scenario
				console.log("300 queue error");
				setIsCheckingCreditReissueStatus(false);
				handleClosePricingModal();
				setErrorText(
					response?.error ||
						`Error pushing data from OB to Encompass with pushId ${queueId}`
				);
				handleOpenErrorModal();
			}
		} catch (err) {
			console.log("300 catch error:", err);
			setIsCheckingCreditReissueStatus(false);

			clearTimeout(checkQueueStatusInterval);
			checkQueueStatusInterval = null;

			handleClosePricingModal();
			setErrorText(
				`Error pushing data from OB to Encompass with pushId ${queueId}`
			);
			handleOpenErrorModal();
		}
	};

	const handleSubmitPricingModal = async () => {
		console.log("100 eligible products:", eligibleProducts);
		console.log("100 expanded product:", expandedProduct);
		console.log("85 selected sub products", selectedRow);

		var formData = new FormData();
		formData.append("ob_loan_id", eligibleProducts?.data?.ob_loan_id);
		formData.append(
			"ob_business_channel_id",
			eligibleProducts?.data?.ob_business_channel_id
		);
		formData.append(
			"ob_originator_id",
			eligibleProducts?.data?.ob_originator_id
		);
		formData.append("product_id", expandedProduct);
		formData.append("search_id", eligibleProducts?.data?.searchId);
		formData.append("rate", selectedRow?.rate);
		formData.append("price", selectedRow?.price);
		formData.append("lock_period", selectedRow?.lockPeriod);

		try {
			const updatedLoanWithProduct = await updateLoanWithProduct({
				formData,
			}).unwrap();
			console.log("125 updated loan with product:", updatedLoanWithProduct);

			if (updatedLoanWithProduct?.message?.toLowerCase()?.includes("success")) {
				var productFormData = new FormData();
				productFormData.append(
					"ob_loan_id",
					eligibleProducts?.data?.ob_loan_id
				);
				productFormData.append(
					"ob_business_channel_id",
					eligibleProducts?.data?.ob_business_channel_id
				);
				productFormData.append(
					"ob_originator_id",
					eligibleProducts?.data?.ob_originator_id
				);

				const pushToEncompassResponse = await pushDataToEncompass({
					formData: productFormData,
				}).unwrap();
				console.log("90 push to encompass response:", pushToEncompassResponse);

				if (pushToEncompassResponse?.status === "Success") {
					checkQueueStatusInterval = setTimeout(
						() => checkQueueStatus(pushToEncompassResponse?.data?.pushQueueId),
						0
					);
				} else {
					console.log("290 error else:", pushToEncompassResponse);

					handleClosePricingModal();
					setErrorText(
						pushToEncompassResponse?.error ||
							"Error updating loan with OB Product."
					);
					handleOpenErrorModal();
				}
			} else {
				handleClosePricingModal();
				setErrorText("Error updating loan with OB Product.");
				handleOpenErrorModal();
			}
		} catch (err) {
			console.log("290 error catch:", err);

			handleClosePricingModal();
			setErrorText(err?.data?.message);
			handleOpenErrorModal();
		}
	};

	const [error, setError] = useState("");

	const getIneligibleProductList = async ({
		ob_business_channel_id,
		ob_originator_id,
		ob_loan_id,
		searchId,
	}) => {
		try {
			console.log("200 eligibleProducts:", eligibleProducts);

			const ineligibleProductList = await getIneligibleProducts({
				ob_business_channel_id,
				ob_originator_id,
				ob_loan_id,
				searchId,
			}).unwrap();

			console.log(
				"120 ineligible products:",
				ineligibleProductList?.data?.ineligibleProducts
			);
			setIneligibleProducts(ineligibleProductList?.data?.ineligibleProducts);
		} catch (err) {
			console.log("270 error:", err);
			setErrorText(
				err?.data?.message?.errors || "Error fetching ineligible products."
			);
			handleOpenErrorModal();
		}
	};

	const updateFieldsAndRunPricing = async () => {
		let valuesArr = [];

		// Update Encompass field values
		if (
			(selectedUserWithLoanChannel(userDetails, impersonatedUser)
				?.loan_channel === LOAN_CHANNEL_CONSTANTS.CB ||
				selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) &&
			compensationPlan !== ""
		) {
			valuesArr.push({ id: "LCP.X1", value: compensationPlan });

			if (compensationPlan === "Lender Paid") {
				valuesArr.push({ id: "389", value: "" });
				valuesArr.push({ id: "1620", value: "" });
			}
		}

		try {
			const updateFieldValues = await updateFields({
				loan_guid: loanGuid || params?.id,
				field_values: valuesArr,
			}).unwrap();

			console.log(
				"LoanPricingApplication: updateFieldsAndRunPricing: updateFieldValues",
				updateFieldValues
			);

			if (
				updateFieldValues?.response === "Field values updated successfully."
			) {
				setError("");
			} else {
				console.log(
					"LoanPricingApplication: updateFieldsAndRunPricing: else err",
					updateFieldValues?.response
				);
				setError(updateFieldValues?.response);
				return;
			}
		} catch (err) {
			console.log(
				"LoanPricingApplication: updateFieldsAndRunPricing: catch err",
				err
			);
			return;
		}

		if (loanEmail !== undefined && loanEmail !== "") {
			getEligibleProductList();
		}
	};

	const getEligibleProductList = async () => {
		/**
		 * Method to run OB Pricing to get products
		 */

		console.log("275 loan id:", loanGuid);

		try {
			const loanDetails = await getLoan({
				loanid: loanGuid,
			}).unwrap();

			console.log("120 loan data:", loanDetails?.response);
			console.log("120 loan number:", loanDetails?.response?.loanNumber);

			setLoanData(loanDetails?.response);

			let propertyType;

			let isPropertyNotLocatedInProject =
				loanDetails?.response?.notInProjectIndicator;

			if (isPropertyNotLocatedInProject) {
				// Single Family or 2-4 Unit Property or 5-8 Unit Residential or 5-8 Unit Mixed Use
				propertyType = "SingleFamily";
			} else {
				propertyType = "";
			}

			console.log("360 property type:", propertyType);

			console.log("400 userDetails:", userDetails);
			console.log("400 impersonatedUser:", impersonatedUser);
			console.log(
				"400 selected:",
				selectedUserWithLoanChannel(userDetails, impersonatedUser)?.loan_channel
			);
			console.log(
				"400 selected email:",
				selectedUserWithLoanChannel(userDetails, impersonatedUser)?.email
			);

			let loanChannel;
			let email;
			let regex = /\(([^)]+)\)/;

			if (
				((impersonatedUser?.email === undefined ||
					impersonatedUser?.email === null) &&
					userDetails?.user_roles?.includes("admin") === true) ||
				userDetails?.user_roles?.includes("manager") ||
				userDetails?.user_roles?.includes("AM") ||
				userDetails?.user_roles?.includes("executive") ||
				userDetails?.user_roles?.includes("AE")
			) {
				console.log("500 email:", loanEmail);
				console.log("500 org loan channel:", orgLoanChannel);
				console.log("500 loan channel:", orgLoanChannel?.match(regex)?.[1]);
				loanChannel = orgLoanChannel?.match(regex)?.[1];
				email = loanEmail;
			} else {
				loanChannel = selectedUserWithLoanChannel(
					userDetails,
					impersonatedUser
				)?.loan_channel;
				email = selectedUserWithLoanChannel(
					userDetails,
					impersonatedUser
				)?.email;
			}

			var formData = new FormData();
			formData.append("encompass_loan_guid", loanGuid);
			formData.append(
				"encompass_loan_number",
				loanDetails?.response?.loanNumber
			);
			formData.append("propertyType", propertyType);
			formData.append("loan_channel", loanChannel?.toUpperCase());
			formData.append("broker_email", email);
			formData.append("compensation_paid", compensationPlan);

			const eligibleProductList = await getEligibleProducts({
				formData,
			}).unwrap();

			console.log("120 eligible products:", eligibleProductList);

			if (eligibleProductList?.status?.toLowerCase()?.includes("success")) {
				setEligibleProducts(eligibleProductList);
				getIneligibleProductList({
					ob_business_channel_id:
						eligibleProductList?.data?.ob_business_channel_id,
					ob_originator_id: eligibleProductList?.data?.ob_originator_id,
					ob_loan_id: eligibleProductList?.data?.ob_loan_id,
					searchId: eligibleProductList?.data?.searchId,
				});
			}
		} catch (err) {
			console.log("LoanPricingApplication:get Eligible Product List:err", err);
			if (err?.data?.data?.errors?.includes("LockPending status")) {
				setErrorText(
					"A lock request cannot be made at this time because a lock request is currently pending for this loan."
				);
			} else if (
				err?.data?.message?.toLowerCase()?.includes("locked by another user")
			) {
				setErrorText(
					"This Loan is currently Locked, If you need to update the lock on this loan, please contact Logan's lock desk."
				);
			} else {
				setErrorText(
					err?.data?.message?.errors || "Error fetching eligible products."
				);
			}
			handleOpenErrorModal();
		}
	};

	const handleGetEligibleProductDetails = async (productId) => {
		console.log("160 product id:", productId);

		try {
			const eligibleProductResponse = await getEligibleProductDetails({
				ob_business_channel_id: eligibleProducts?.data?.ob_business_channel_id,
				ob_originator_id: eligibleProducts?.data?.ob_originator_id,
				ob_loan_id: eligibleProducts?.data?.ob_loan_id,
				search_id: eligibleProducts?.data?.searchId,
				product_id: productId,
			}).unwrap();

			console.log("120 eligible product details:", eligibleProductResponse);

			setEligibleProductDetails(eligibleProductResponse);
		} catch (err) {
			console.log("270 error:", err);
			setErrorText(
				err?.data?.message?.errors || "Error fetching eligible product details."
			);
			handleOpenErrorModal();
		}
	};

	useEffect(() => {
		console.log("545 loan email:", loanEmail);

		if (loanEmail !== undefined && loanEmail !== "") {
			getEligibleProductList();
		}
	}, [loanEmail]);

	const findFieldValue = (fieldValues, fieldId) => {
		return fieldValues?.find((o) => o.fieldId === fieldId)?.value;
	};

	const updateField = (fieldValues) => {
		console.log("88 field values:", fieldValues);

		setBorrowerFirstName(findFieldValue(fieldValues, "4000"));
		setBorrowerMiddleName(findFieldValue(fieldValues, "4001"));
		setBorrowerLastName(findFieldValue(fieldValues, "4002"));

		setCoBorrowerFirstName(findFieldValue(fieldValues, "4004"));
		setCoBorrowerMiddleName(findFieldValue(fieldValues, "4005"));
		setCoBorrowerLastName(findFieldValue(fieldValues, "4006"));

		setStreet(findFieldValue(fieldValues, "URLA.X73"));
		setCity(findFieldValue(fieldValues, "12"));
		setState(findFieldValue(fieldValues, "14"));
		setPostalCode(findFieldValue(fieldValues, "15"));

		setLoanEmail(findFieldValue(fieldValues, "3968"));
		setOrgLoanChannel(findFieldValue(fieldValues, "TPO.X14"));
		setCompensationPlan(
			fieldValues?.find((o) => o.fieldId === "LCP.X1")?.value
		);
	};

	const getFieldValues = async (loanId) => {
		console.log("135 loan id:", loanId);
		try {
			const getValues = await field({
				loan_guid: loanId,
				fields:
					"4000,4001,4002,4004,4005,4006,URLA.X73,12,14,15,3968,TPO.X14,LCP.X1",
			}).unwrap();
			console.log("175 all fields:", getValues?.response);
			if (getValues?.response?.length > 0) {
				updateField(getValues?.response);
			}
		} catch (err) {
			console.log("584 error:", err);
		}
	};

	useEffect(() => {
		console.log("435 loan guid:", loanGuid);

		if (loanGuid !== undefined && loanGuid !== "") {
			getFieldValues(loanGuid);
		}
	}, [loanGuid]);

	const handlePrev = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage - 1);
	};

	const handleNextView = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		setLoanApplicationStage(currentStage + 1);
	};

	const handleSubmitApplication = async () => {
		console.log("502 params id:", params?.id);
		console.log("502 loan id:", loanGuid);

		// required fields pricing
		setError("");

		handleOpenPricingModal();
	};

	const handleToggleExpand = (productId) => {
		if (expandedProduct === productId) {
			setExpandedProduct("");
		} else {
			setExpandedProduct(productId);
			handleGetEligibleProductDetails(productId);
		}
	};

	// Handle Enter key press
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === "Enter" && successModalVisible) {
				event.preventDefault(); // Prevent the original button from being triggered
				handleCloseSuccessModal(); // Trigger the button inside the modal
			}
		};

		// Add event listener when the modal is open
		if (successModalVisible) {
			window.addEventListener("keydown", handleKeyDown);
		}

		// Clean up the event listener when the modal closes
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [successModalVisible]);

	return (
		<Stack direction="column" className={styles.stackContainer}>
			<Stack className={styles.bodyContainer}>
				{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
					?.loan_channel === LOAN_CHANNEL_CONSTANTS.CB ||
					selectedUserWithLoanChannel(userDetails, impersonatedUser)
						?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) && (
					<Stack direction="row" className={styles.compensationContainer}>
						<Stack direction="column">
							{/* Compensation Type */}
							{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CB ||
								selectedUserWithLoanChannel(userDetails, impersonatedUser)
									?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) && (
								<Stack
									direction="column"
									className={styles.cardHeaderContainer}
								>
									<Heading5 text="Compensation Type" fontType="semibold" />
									<Body3 text="Provide details about the loan" />
									<Body2
										text={`The pricing displayed below is based upon the Compensation Type\nselected. If you wish to have a different Compensation Type on\nthe loan, change the selection and click the "Run Pricing" button.`}
										extraClass={styles.pricingBody}
									/>
								</Stack>
							)}

							{(selectedUserWithLoanChannel(userDetails, impersonatedUser)
								?.loan_channel === LOAN_CHANNEL_CONSTANTS.CB ||
								selectedUserWithLoanChannel(userDetails, impersonatedUser)
									?.loan_channel === LOAN_CHANNEL_CONSTANTS.WHS) && (
								<Stack direction="row" className={styles.formContainer}>
									{/* Loan Compensation Plan */}
									<Stack direction="column" className={styles.inputContainer}>
										<InputLabel htmlFor="select-lcp">
											<Heading6
												text="Loan Compensation Plan"
												fontType="semibold"
												extraClass={styles.inputHeader}
											/>
										</InputLabel>

										<SecondarySelect
											id="select-lcp"
											displayEmpty={true}
											fullWidth={true}
											value={compensationPlan}
											handleChange={(event) =>
												setCompensationPlan(event.target.value)
											}
											options={compensationPlanOptions}
											disabled={viewLoan}
										/>
									</Stack>
								</Stack>
							)}
						</Stack>
						<PrimaryButton
							text="Run Pricing"
							disabled={viewLoan}
							onClick={updateFieldsAndRunPricing}
							extraClass={styles.pricingButton}
						/>
					</Stack>
				)}

				{viewLoan && (
					<ViewLoanInfoCard
						loanNumber={loanNumber}
						borrower={{
							firstName: borrowerFirstName,
							middleName: borrowerMiddleName,
							lastName: borrowerLastName,
						}}
						coBorrower={{
							firstName: coBorrowerFirstName,
							middleName: coBorrowerMiddleName,
							lastName: coBorrowerLastName,
						}}
						propertyInfo={{
							addressLineText: street,
							city,
							state,
							postalCode,
						}}
					/>
				)}

				<Stack direction="column" className={styles.cardHeaderContainer}>
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						aria-label="pricing tabs"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#76D99E",
							},
						}}
					>
						<Tab
							className={
								tabValue === 0
									? styles.tabBtnContainer + " " + styles.activeTabBtnContainer
									: styles.tabBtnContainer
							}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text={`Eligible Pricing Results (${
											eligibleProducts?.data?.products
												? eligibleProducts?.data?.products?.length
												: 0
										})`}
										fontType="semibold"
										extraClass={
											tabValue === 0 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(0)}
						/>
						<Tab
							className={
								tabValue === 1
									? styles.tabBtnContainer + " " + styles.activeTabBtnContainer
									: styles.tabBtnContainer
							}
							label={
								<Stack direction="row" className={styles.tabLabelContainer}>
									<Heading6
										text={`Ineligible Pricing Results (${
											ineligibleProducts ? ineligibleProducts?.length : 0
										})`}
										fontType="semibold"
										extraClass={
											tabValue === 1 ? styles.activeTabText : styles.tabText
										}
									/>
								</Stack>
							}
							{...a11yProps(1)}
						/>
					</Tabs>
				</Stack>

				<TabPanel
					index={0}
					value={tabValue}
					className={tabValue === 0 ? styles.tabPanelContainer : ""}
				>
					{eligibleProducts?.data?.products?.length > 0 ? (
						<EligibleFilter
							eligibleProducts={eligibleProducts?.data?.products}
							expandedProduct={expandedProduct}
							toggleExpand={handleToggleExpand}
							eligibleProductDetails={eligibleProductDetails?.data}
							selectedRow={selectedRow}
							setSelectedRow={setSelectedRow}
							setSelectedProduct={setSelectedProduct}
							toggleDrawer={toggleDrawer}
							hidePriceColumn={
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === TPO_SECONDARY ||
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === BROKER_ADMIN
									? false
									: selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									  selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
							}
							hidePointsPercentageColumn={
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === TPO_SECONDARY
									? false
									: selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									  selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
							}
							hidePointsValueColumn={
								selectedUserWithAccessLevel(userDetails, impersonatedUser)
									?.access_level === TPO_SECONDARY
									? false
									: selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.NDC ||
									  selectedUserWithLoanChannel(userDetails, impersonatedUser)
											?.loan_channel === LOAN_CHANNEL_CONSTANTS.CORR
							}
						/>
					) : (
						<EmptyTextContainer
							headerText="No products available"
							bodyText={`Please click the Ineligible button above to see why no products are available`}
						/>
					)}
				</TabPanel>

				<TabPanel
					index={1}
					value={tabValue}
					className={tabValue === 1 ? styles.tabPanelContainer : ""}
				>
					{ineligibleProducts?.length > 0 ? (
						<IneligibleFilter ineligibleProducts={ineligibleProducts || []} />
					) : (
						<EmptyTextContainer
							headerText="No products available"
							bodyText={`There are no Ineligible products available for this loan`}
						/>
					)}
				</TabPanel>
			</Stack>

			{/* loan flow error */}
			{error !== "" && (
				<RequiredError
					headerText="Missing fields"
					text={
						error.includes("Something went wrong")
							? "Updates Not Received by LOS. Please Try Again."
							: error.includes("provide a value")
							? "Please provide a value for all fields highlighted above."
							: error
					}
				/>
			)}

			{/* footer buttons */}
			<Stack direction="row" className={styles.footerContainer}>
				<SecondaryButton
					text="Previous"
					startIcon={<ArrowBackOutlined className={styles.prevIcon} />}
					onClick={handlePrev}
				/>
				<PrimaryButton
					text={viewLoan ? "Continue" : "Select loan option and continue"}
					endIcon={<ArrowForwardOutlined className={styles.nextIcon} />}
					disabled={viewLoan ? false : Object.keys(selectedRow)?.length === 0}
					onClick={() =>
						viewLoan ? handleNextView() : handleSubmitApplication()
					}
				/>
			</Stack>
			{/* drawer */}
			<TemporaryDrawer
				// drawer props
				drawerOpen={drawerOpen}
				drawerDirection="right"
				drawerType={drawerType}
				toggleDrawer={toggleDrawer}
				handleCloseDrawer={handleCloseDrawer}
				handleOpenProcessingModal={handleOpenProcessingModal}
				handleCloseProcessingModal={handleCloseProcessingModal}
				setProcessingText={setProcessingText}
				handleOpenSuccessModal={handleOpenSuccessModal}
				setSuccessText={setSuccessText}
				handleOpenErrorModal={handleOpenErrorModal}
				setErrorText={setErrorText}
				// custom props
				drawerData={drawerData}
			/>
			{/* custom modals */}
			<LoaderTextModal
				open={
					isGettingLoan ||
					isGettingEligibleProducts ||
					isGettingEligibleProductDetails ||
					isGettingIneligibleProducts ||
					isUpdatingLoanWithProduct ||
					isPushingDataToEncompass ||
					isCheckingCreditReissueStatus
				}
				loadingTextLine1={"Obtaining Pricing & Eligibility"}
				loadingTextLine2={"This will take a few minutes"}
			/>
			<LoaderModal open={isUpdatingFields} />
			<ProcessingModal open={processingModalVisible} text={processingText} />
			<SuccessModal
				open={successModalVisible}
				handleClose={handleCloseSuccessModal}
				text={successText}
			/>
			<ErrorModal
				open={errorModalVisible}
				text={errorText}
				handleClose={handleCloseErrorModal}
			/>
			<PricingModal
				open={pricingModal}
				data={selectedRow}
				productName={selectedProduct}
				confirmText="Register loan with Logan Pricing Desk"
				handleConfirm={handleSubmitPricingModal}
				closeText="Go back"
				handleClose={handleClosePricingModal}
			/>
		</Stack>
	);
};

export default LoanPricingApplication;
