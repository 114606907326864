export const LOAN_CHANNEL_CONSTANTS = {
	CB: "CB",
	NDC: "NDC",
	CORR: "CORR",
	WHS: "WHS",
};

export const TPO_SECONDARY = "TPO Secondary";

export const BROKER_ADMIN = "broker admin";
